import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb } from "../components/Section"
import CallNowSection from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"
import ArticleCard from "../components/ArticleCard"
import BreakpointUp from "../components/Media/BreakpointUp"

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      @media (min-width: 1600px) {
        object-position: left center !important;
      }
    }
  }
`

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.lg`        
        margin:0 -25px;    
    `}
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
`
const SectionGridTitle = styled.h2`
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`
const SectionListGrid = styled.ul`
  margin: 0;
  padding: 0;
`
const SectionItemGrid = styled.li`
  margin: 0 0 30px;
  padding: 0;
  .card {
    flex-direction: column-reverse;
    ${BreakpointUp.lg`     
        flex-direction: row-reverse;
      `}
  }

  .gatsby-image-wrapper {
    max-height: 500px;
  }
`
const Infographics = ({ data, location }) => {
  const allInfographics = data.allContentfulInfographic.edges
  return (
    <Layout location={location}>
      <Seo title="Infographics By Coast to Coast Carports" description="Coast To Coast Carports' latest Infographics for you to view and share." />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Infographics</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="120px" xpb="60px" bgColor="#FAFAFA">
        <BackgroundImage>
          <StaticImage
            src="../images/featured-articles.png"
            alt="featured-articles"
          />
        </BackgroundImage>
        <div className="container">
          <SectionPageTitle textAlign="left">Infographics</SectionPageTitle>
        </div>
      </Section>
      <Section pt="70px" pb="70px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionGrid>
            <SectionGridLeft>
              <SectionGridTitle>Explore All Infographics</SectionGridTitle>
              <SectionListGrid>
                {allInfographics.map(item => (
                  <SectionItemGrid key={v4()}>
                    <ArticleCard data={item.node} />
                  </SectionItemGrid>
                ))}
              </SectionListGrid>
            </SectionGridLeft>
          </SectionGrid>
        </div>
      </Section>
      <CallNowSection />
    </Layout>
  )
}

export default Infographics

export const pageQuery = graphql`
  query InfographicsPageQuery {
    allContentfulInfographic {
      edges {
        node {
          id
          title
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            gatsbyImageData
          }
          createdAt
          postDate(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`
